.hok-data-select-option
{
    padding:5px;
}

.hok-data-select-option-selected
{
    background-color:rgba(0,0,0,.1);
}

.hok-data-select-option-container
{
    overflow-y:auto;
    max-height:50vh;
    height:50vh; /* no flickering */
}

.hok-data-select-option-container-2
{
    overflow-y:auto;
    max-height:50vh;
}

.hok-data-select-button:focus
{
    /* background-color:lightsteelblue; */
    background-color:#d0e4fe;
}


.hok-data-select-button
{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}