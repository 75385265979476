.bottom-0
{
    bottom:0;
}

.right-0
{
    right:0;
}


.top-0
{
    top:0;
}

.bg-none
{
    background:none;
    background-color: unset!important;
}

.cursor-pointer
{
    cursor:pointer;
}



/* migrate this later */
.form-group {
    margin-bottom: 1rem;
}


@media print {
    header
    {
        display:none;
    }
    
}